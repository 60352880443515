/**
 * @generated SignedSource<<a1e311bcbdc38389b2613f6ee0bf05ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadJobStatus = "STATUS_ABANDONED" | "STATUS_COMPLETE" | "STATUS_INVALID" | "STATUS_IN_PROGRESS" | "STATUS_PROCESS" | "STATUS_UNKNOWN" | "STATUS_UPLOADED" | "%future added value";
export type SubscriberUploadSubscriptionFlow = "SUBSCRIPTION_FLOW_DOUBLE_OPT_IN" | "SUBSCRIPTION_FLOW_SILENT_OPT_IN" | "SUBSCRIPTION_FLOW_SINGLE_OPT_IN" | "SUBSCRIPTION_FLOW_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob$data = {
  readonly batchId: number;
  readonly id: string;
  readonly parameters: {
    readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters" | "useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters">;
  };
  readonly status: SubscriberUploadJobStatus;
  readonly subscriptionFlow: SubscriberUploadSubscriptionFlow;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob" | "UploadDateTableCell_SettingsUI_SubscriberUploadJob" | "UploadStatusTableCell_SettingsUI_SubscriberUploadJob">;
  readonly " $fragmentType": "useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob";
};
export type useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob"
};

(node as any).hash = "9e9193e4cb1ef7d38c40e1700f325ab2";

export default node;
