/**
 * @generated SignedSource<<ef9faeafa8399ee7d8052352e5a1a1e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadSubscriptionType = "SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_EMAIL_MARKETING" | "SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_TEXT_MARKETING" | "SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters$data = {
  readonly subscriptionTypes: SubscriberUploadSubscriptionType;
  readonly " $fragmentType": "useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters";
};
export type useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters$key = {
  readonly " $data"?: useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters"
};

(node as any).hash = "e47294d52cc640b6a178dabcc51588d5";

export default node;
