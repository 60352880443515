/**
 * @generated SignedSource<<4ebff3462fc6985e43f837614d350b28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionUploads_SubscriberUpload_SettingsUI_Company$data = {
  readonly subscriberUploads: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob">;
      };
    }>;
  } | null;
  readonly " $fragmentType": "useSubscriptionUploads_SubscriberUpload_SettingsUI_Company";
};
export type useSubscriptionUploads_SubscriberUpload_SettingsUI_Company$key = {
  readonly " $data"?: useSubscriptionUploads_SubscriberUpload_SettingsUI_Company$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_SubscriberUpload_SettingsUI_Company">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "subscriptionTypes",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSubscriptionUploads_SubscriberUpload_SettingsUI_Company",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 500
        }
      ],
      "concreteType": "SubscriberUploadJobConnection",
      "kind": "LinkedField",
      "name": "subscriberUploads",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubscriberUploadJobEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SubscriberUploadJob",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useSubscriptionUploads_formatUploadNode_SettingsUI_SubscriberUploadJob",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UploadDateTableCell_SettingsUI_SubscriberUploadJob"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UploadStatusTableCell_SettingsUI_SubscriberUploadJob"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SubscriberUploadTableRow_SettingsUI_SubscriberUploadJob"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "batchId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SubscriberUploadJobParameters",
                      "kind": "LinkedField",
                      "name": "parameters",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters",
                          "selections": (v0/*: any*/),
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "useSubscriptionUploads_getSubscriberType_SettingsUI_SubscriberUploadJobParameters",
                          "selections": (v0/*: any*/),
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "subscriptionFlow",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "subscriberUploads(first:500)"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "637b6548dfc40c17efb19031e02701fa";

export default node;
