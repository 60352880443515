/**
 * @generated SignedSource<<8cb992763e81a8d5890cf3a939016118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriberUploadUpdateStrategy = "SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE" | "SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES" | "SUBSCRIBER_UPDATE_STRATEGY_UNKNOWN" | "SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CompletedCountTableCell_SettingsUI_SubscriberUploadJob$data = {
  readonly jobUploadResults: {
    readonly existingActiveOptOutSubscribersCount: number;
    readonly existingActiveSubscribersCount: number;
    readonly existingJoinedSubscribersCount: number;
    readonly invalidSubscribersCount: number;
    readonly validSubscribersCount: number;
  };
  readonly parameters: {
    readonly strategy: SubscriberUploadUpdateStrategy;
  };
  readonly " $fragmentType": "CompletedCountTableCell_SettingsUI_SubscriberUploadJob";
};
export type CompletedCountTableCell_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: CompletedCountTableCell_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompletedCountTableCell_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedCountTableCell_SettingsUI_SubscriberUploadJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadJobParameters",
      "kind": "LinkedField",
      "name": "parameters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "strategy",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriberUploadJobResults",
      "kind": "LinkedField",
      "name": "jobUploadResults",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "existingActiveOptOutSubscribersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "existingActiveSubscribersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "existingJoinedSubscribersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invalidSubscribersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validSubscribersCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubscriberUploadJob",
  "abstractKey": null
};

(node as any).hash = "4319ce79e7196f2ce292572b672812a2";

export default node;
