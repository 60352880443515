/**
 * @generated SignedSource<<78429bb37f6424754c88bec0eeaa069c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadSubscriptionType = "SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_EMAIL_MARKETING" | "SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_TEXT_MARKETING" | "SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters$data = {
  readonly subscriptionTypes: SubscriberUploadSubscriptionType;
  readonly " $fragmentType": "useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters";
};
export type useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters$key = {
  readonly " $data"?: useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useSubscriptionUploads_getChannel_SettingsUI_SubscriberUploadJobParameters"
};

(node as any).hash = "798b01c9263d68894c93881cf6e03d9f";

export default node;
